.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-container{
  padding: 0.3rem 2rem;
  background-color: #e9ecef;
}
.text-align-left{
  text-align: left;
}
.text-align-right{
  text-align: right;
}
.text-align-center{
  text-align: center;
}
.no-right-padding{
  padding-right: 0;
}
.no-left-padding{
  padding-left: 0;
}
.padding-top-bottom-1rem{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.padding-top-bottom-point-5-rem{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.padding-left-point-3-rem{
  padding-left: 0.3rem;
}
.padding-right-point-3-rem{
  padding-right: 0.3rem;
}
.display-flex{
  display: flex;
}
.eds-label{
  color: #333 !important;
}
.eds-modal__top-fade, .eds-modal__bottom-fade{
  background: none !important;
}
.eds-toggle .eds-toggle__input:disabled:checked~span {
  background-color: var(--eds-color-background-success) !important;
}
.activeHeaderClass{
  border-bottom: 4px solid #F6F6F6;
  color: #fff !important;
}

.eds-command-bar{
  min-height: 48px !important;
  height: auto !important;
}
.eds-type--subtitle-2{
  display: inline-block;
}